import { Controller } from '@hotwired/stimulus'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = [
    'track',
    'thumb',
    'tooltip',
    'input',
    'progress',
    'stepsContainer'
  ]

  static values = {
    from: { type: Number, default: 1 },
    to: { type: Number, default: 10 },
    value: { type: Number, default: 1 }
  }

  static THROTTLE_DELAY = 50
  static DEBOUNCE_DELAY = 100

  connect () {
    this.validateRange()
    this.initializeState()
    this.setupEventListeners()

    document.addEventListener('turbo:render', this.initializeState.bind(this))
  }

  disconnect () {
    this.removeEventListeners()
    document.removeEventListener(
      'turbo:render',
      this.initializeState.bind(this)
    )
  }

  // Private methods

  validateRange () {
    if (this.fromValue >= this.toValue) {
      throw new Error("'from' value must be less than 'to' value")
    }
  }

  initializeState () {
    this.value = this.inputTarget.value
      ? parseInt(this.inputTarget.value)
      : this.fromValue
    this.steps = this.toValue - this.fromValue
    this.stepSize = 100 / this.steps

    this.inputTarget.value = this.value

    this.updateSlider()
  }

  setupEventListeners () {
    this.boundHandlers = {
      move: this.handleMove.bind(this),
      end: this.handleEnd.bind(this),
      mouseDown: this.handleMouseDown.bind(this),
      touchStart: this.handleTouchStart.bind(this)
    }

    this.thumbTarget.addEventListener(
      'mousedown',
      this.boundHandlers.mouseDown
    )
    this.thumbTarget.addEventListener(
      'touchstart',
      this.boundHandlers.touchStart
    )
  }

  removeEventListeners () {
    this.thumbTarget.removeEventListener(
      'mousedown',
      this.boundHandlers.mouseDown
    )
    this.thumbTarget.removeEventListener(
      'touchstart',
      this.boundHandlers.touchStart
    )

    document.removeEventListener('mousemove', this.boundHandlers.move)
    document.removeEventListener('touchmove', this.boundHandlers.move)
    document.removeEventListener('mouseup', this.boundHandlers.end)
    document.removeEventListener('touchend', this.boundHandlers.end)
  }

  handleMouseDown (event) {
    event.preventDefault()
    document.addEventListener('mousemove', this.boundHandlers.move)
    document.addEventListener('mouseup', this.boundHandlers.end)
  }

  handleTouchStart (event) {
    event.preventDefault()
    document.addEventListener('touchmove', this.boundHandlers.move)
    document.addEventListener('touchend', this.boundHandlers.end)
  }

  handleMove = throttle((event) => {
    const trackRect = this.trackTarget.getBoundingClientRect()
    const clientX = event.type.includes('mouse')
      ? event.clientX
      : event.touches[0].clientX
    const position = this.calculatePosition(clientX, trackRect)
    const value = Math.round(this.fromValue + (position / 100) * this.steps)

    this.updateValue(value)
  }, this.THROTTLE_DELAY)

  handleEnd = debounce(() => {
    this.inputTarget.dispatchEvent(new Event('change'))
    this.removeDocumentListeners()
  }, this.DEBOUNCE_DELAY)

  removeDocumentListeners () {
    document.removeEventListener('mousemove', this.boundHandlers.move)
    document.removeEventListener('touchmove', this.boundHandlers.move)
    document.removeEventListener('mouseup', this.boundHandlers.end)
    document.removeEventListener('touchend', this.boundHandlers.end)
  }

  calculatePosition (clientX, trackRect) {
    const position = ((clientX - trackRect.left) / trackRect.width) * 100
    return Math.max(0, Math.min(100, position))
  }

  updateValue (value) {
    const boundValue = Math.max(this.fromValue, Math.min(this.toValue, value))
    this.valueValue = boundValue
    this.inputTarget.value = boundValue
    this.updateSlider()
  }

  updateSlider () {
    const index = this.valueValue - this.fromValue
    const position = index / this.steps * 100

    this.thumbTarget.style.left = `${position}%`
    this.progressTarget.style.width = `${position}%`
    this.tooltipTarget.textContent = Math.round(this.valueValue)

    this.updateStepStyles()
  }

  updateStepStyles () {
    this.stepsContainerTarget.querySelectorAll('div').forEach((step, index) => {
      const stepValue = index + 1
      step.ariaSelected = stepValue <= this.valueValue
    })
  }

  clickValue (event) {
    this.updateValue(event.params.value)
    this.inputTarget.dispatchEvent(new Event('change'))
  }
}
