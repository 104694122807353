import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static values = {
    behavior: { type: String, default: 'auto' }
  }

  connect () {
    useIntersection(this)
    setTimeout(() => {
      this.element.dispatchEvent(new Event('connect'))
    }, 50) // delay 50ms to ensure the intersection observer can be registered
  }

  scrollIfInvisible () {
    if (!this.isVisible()) {
      this.scroll()
    }
  }

  scroll () {
    this.element.scrollIntoView({ behavior: this.behaviorValue })
  }
}
